// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sd from "common/src/tft-static/Sd.bs.js";
import * as Data from "common/src/data/Data.bs.js";
import * as Patch from "common/src/Patch.bs.js";
import * as Config from "common/src/Config.bs.js";
import * as Region from "common/src/Region.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Aperture from "common/src/Aperture.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

var allLocales = [
  "en",
  "de",
  "ko",
  "pt",
  "ja",
  "fr",
  "es",
  "zh",
  "vi"
];

function parseQs(search) {
  return Belt_Array.keepMap(search.split("&"), (function (s) {
                if (s === "") {
                  return ;
                }
                var split = s.split("=");
                var match = Belt_Array.get(split, 0);
                var match$1 = Belt_Array.get(split, 1);
                if (match !== undefined && match$1 !== undefined) {
                  return [
                          match,
                          match$1
                        ];
                }
                
              }));
}

function toQs(query) {
  var q = Belt_Array.keepMap(query, (function (param) {
            var v = param[1];
            if (v !== "") {
              return param[0] + "=" + v;
            }
            
          })).join("&");
  if (q.length > 0) {
    return "?" + q;
  } else {
    return q;
  }
}

function fromPath(_path, search) {
  while(true) {
    var path = _path;
    var qs = parseQs(search);
    var slug = Belt_List.keep(path, (function (v) {
            return v !== "";
          }));
    if (!slug) {
      return /* Home */0;
    }
    var hd = slug.hd;
    if (allLocales.includes(hd)) {
      _path = slug.tl;
      continue ;
    }
    switch (hd) {
      case "augments" :
          var match = slug.tl;
          if (!match) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Augments */3,
                      aperture: Aperture.$$default
                    }
                  };
          }
          var slug$1 = match.tl;
          var id = match.hd;
          var isId = Belt_Option.isSome(Js_dict.get(Data.augments(Config.statsSet), id));
          if (isId) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Augment */7,
                      id: id,
                      aperture: Aperture.fromUrl(Belt_List.toArray(slug$1))
                    }
                  };
          }
          var slug$2 = {
            hd: id,
            tl: slug$1
          };
          return {
                  TAG: /* Stats */0,
                  _0: {
                    TAG: /* Augments */3,
                    aperture: Aperture.fromUrl(Belt_List.toArray(slug$2))
                  }
                };
      case "duo-compositions" :
          var match$1 = slug.tl;
          if (match$1) {
            if (match$1.hd === "top" && !match$1.tl) {
              return {
                      TAG: /* Stats */0,
                      _0: {
                        TAG: /* DuoComps */12,
                        _0: true
                      }
                    };
            } else {
              return /* Home */0;
            }
          } else {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* DuoComps */12,
                      _0: false
                    }
                  };
          }
      case "exalted" :
          var slug$3 = slug.tl;
          if (!slug$3) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Exalted */8,
                      aperture: Aperture.fromUrl(Belt_List.toArray(slug$3))
                    }
                  };
          }
          var match$2 = slug$3.tl;
          if (!match$2) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Exalted */8,
                      aperture: Aperture.fromUrl(Belt_List.toArray(slug$3))
                    }
                  };
          }
          var slug$4 = match$2.tl;
          var tier = match$2.hd;
          var gameType = slug$3.hd;
          var gameTypeV = Belt_Int.fromString(gameType);
          var tierV = Belt_Int.fromString(tier);
          if (gameTypeV !== undefined && tierV !== undefined && gameTypeV < 1000) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* ExaltedDetails */9,
                      gameType: gameTypeV,
                      tier: tierV,
                      aperture: Aperture.fromUrl(Belt_List.toArray(slug$4))
                    }
                  };
          }
          var slug_1 = {
            hd: tier,
            tl: slug$4
          };
          var slug$5 = {
            hd: gameType,
            tl: slug_1
          };
          return {
                  TAG: /* Stats */0,
                  _0: {
                    TAG: /* Exalted */8,
                    aperture: Aperture.fromUrl(Belt_List.toArray(slug$5))
                  }
                };
          break;
      case "explorer" :
          if (slug.tl) {
            return /* Home */0;
          } else {
            return {
                    TAG: /* Stats */0,
                    _0: /* Explorer */0
                  };
          }
      case "explorer-advanced" :
          if (slug.tl) {
            return /* Home */0;
          } else {
            return {
                    TAG: /* Stats */0,
                    _0: /* AdvancedExplorer */1
                  };
          }
      case "hyperroll-compositions" :
          var match$3 = slug.tl;
          if (match$3) {
            if (match$3.hd === "top" && !match$3.tl) {
              return {
                      TAG: /* Stats */0,
                      _0: {
                        TAG: /* HrComps */11,
                        _0: true
                      }
                    };
            } else {
              return /* Home */0;
            }
          } else {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* HrComps */11,
                      _0: false
                    }
                  };
          }
      case "info" :
          var match$4 = slug.tl;
          if (!match$4) {
            return {
                    TAG: /* Info */6,
                    _0: {
                      TAG: /* PatchNotes */7,
                      _0: undefined
                    }
                  };
          }
          switch (match$4.hd) {
            case "augments" :
                if (match$4.tl) {
                  return /* Home */0;
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: {
                            TAG: /* Augments */6,
                            _0: undefined
                          }
                        };
                }
            case "cheat-sheet" :
                if (match$4.tl) {
                  return /* Home */0;
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: /* CheatSheet */4
                        };
                }
            case "glossary" :
                if (match$4.tl) {
                  return /* Home */0;
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: /* Glossary */3
                        };
                }
            case "gold-exp" :
                if (match$4.tl) {
                  return /* Home */0;
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: /* GoldExp */2
                        };
                }
            case "hyperroll" :
                if (match$4.tl) {
                  return /* Home */0;
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: /* Hyperroll */1
                        };
                }
            case "items" :
                var match$5 = match$4.tl;
                if (match$5) {
                  if (match$5.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Info */6,
                            _0: {
                              TAG: /* Item */5,
                              _0: undefined,
                              _1: match$5.hd
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: {
                            TAG: /* Items */4,
                            _0: undefined
                          }
                        };
                }
            case "patch-notes" :
                var match$6 = match$4.tl;
                if (match$6) {
                  if (match$6.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Info */6,
                            _0: {
                              TAG: /* PatchNotes */7,
                              _0: match$6.hd
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: {
                            TAG: /* PatchNotes */7,
                            _0: undefined
                          }
                        };
                }
            case "pbe" :
                if (match$4.tl) {
                  return /* Home */0;
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: /* Pbe */5
                        };
                }
            case "set-8" :
                var match$7 = match$4.tl;
                if (!match$7) {
                  return /* Home */0;
                }
                if (match$7.hd !== "tables") {
                  return /* Home */0;
                }
                var match$8 = match$7.tl;
                if (!match$8) {
                  return /* Home */0;
                }
                switch (match$8.hd) {
                  case "admin" :
                      if (match$8.tl) {
                        return /* Home */0;
                      } else {
                        return {
                                TAG: /* Info */6,
                                _0: /* TablesAdmin */7
                              };
                      }
                  case "underground-heist" :
                      if (match$8.tl) {
                        return /* Home */0;
                      } else {
                        return {
                                TAG: /* Info */6,
                                _0: /* TablesUnderground */6
                              };
                      }
                  default:
                    return /* Home */0;
                }
            case "set-update" :
                if (match$4.tl) {
                  return /* Home */0;
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: /* SetUpdate */8
                        };
                }
            case "traits" :
                var match$9 = match$4.tl;
                if (match$9) {
                  if (match$9.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Info */6,
                            _0: {
                              TAG: /* Trait */2,
                              _0: undefined,
                              _1: Belt_Option.getExn(Sd.fromUrlName(match$9.hd, 130))
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: {
                            TAG: /* Traits */3,
                            _0: undefined
                          }
                        };
                }
            case "units" :
                var match$10 = match$4.tl;
                if (match$10) {
                  if (match$10.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Info */6,
                            _0: {
                              TAG: /* Unit */0,
                              _0: undefined,
                              _1: Belt_Option.getExn(Sd.fromUrlName(match$10.hd, 130))
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* Info */6,
                          _0: {
                            TAG: /* Units */1,
                            _0: undefined
                          }
                        };
                }
            default:
              return /* Home */0;
          }
      case "items" :
          var match$11 = slug.tl;
          if (!match$11) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Items */2,
                      aperture: Aperture.$$default
                    }
                  };
          }
          var slug$6 = match$11.tl;
          var id$1 = match$11.hd;
          var isId$1 = Belt_Option.isSome(Js_dict.get(Data.items(Config.statsSet), id$1));
          if (isId$1) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Item */6,
                      id: id$1,
                      aperture: Aperture.fromUrl(Belt_List.toArray(slug$6))
                    }
                  };
          }
          var slug$7 = {
            hd: id$1,
            tl: slug$6
          };
          return {
                  TAG: /* Stats */0,
                  _0: {
                    TAG: /* Items */2,
                    aperture: Aperture.fromUrl(Belt_List.toArray(slug$7))
                  }
                };
      case "leaderboards" :
          var match$12 = slug.tl;
          if (!match$12) {
            return {
                    TAG: /* Leaderboards */7,
                    _0: {
                      TAG: /* Ranked */0,
                      _0: undefined
                    }
                  };
          }
          var region = match$12.hd;
          switch (region) {
            case "bookmarks" :
                if (match$12.tl) {
                  return /* Home */0;
                } else {
                  return {
                          TAG: /* Leaderboards */7,
                          _0: /* Bookmarks */0
                        };
                }
            case "custom" :
                var match$13 = match$12.tl;
                if (match$13) {
                  if (match$13.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Leaderboards */7,
                            _0: {
                              TAG: /* Custom */4,
                              _0: match$13.hd
                            }
                          };
                  }
                }
                break;
            case "duo" :
                var match$14 = match$12.tl;
                if (match$14) {
                  if (match$14.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Leaderboards */7,
                            _0: {
                              TAG: /* Duo */2,
                              _0: Region.fromName(match$14.hd)
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* Leaderboards */7,
                          _0: {
                            TAG: /* Duo */2,
                            _0: undefined
                          }
                        };
                }
            case "hyperroll" :
                var match$15 = match$12.tl;
                if (match$15) {
                  if (match$15.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Leaderboards */7,
                            _0: {
                              TAG: /* Hyperroll */1,
                              _0: Region.fromName(match$15.hd)
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* Leaderboards */7,
                          _0: {
                            TAG: /* Hyperroll */1,
                            _0: undefined
                          }
                        };
                }
            case "ranked" :
                var match$16 = match$12.tl;
                if (match$16) {
                  if (match$16.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Leaderboards */7,
                            _0: {
                              TAG: /* Ranked */0,
                              _0: Region.fromName(match$16.hd)
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* Leaderboards */7,
                          _0: {
                            TAG: /* Ranked */0,
                            _0: undefined
                          }
                        };
                }
            case "unit" :
                var match$17 = match$12.tl;
                if (match$17) {
                  if (match$17.tl) {
                    return /* Home */0;
                  } else {
                    return {
                            TAG: /* Leaderboards */7,
                            _0: {
                              TAG: /* Unit */3,
                              _0: Sd.fromUrlName(match$17.hd, 95)
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* Leaderboards */7,
                          _0: {
                            TAG: /* Unit */3,
                            _0: undefined
                          }
                        };
                }
            default:
              
          }
          if (match$12.tl) {
            return /* Home */0;
          } else {
            return {
                    TAG: /* Leaderboards */7,
                    _0: {
                      TAG: /* Ranked */0,
                      _0: Region.fromName(region)
                    }
                  };
          }
          break;
      case "loaded-dice" :
          if (slug.tl) {
            return /* Home */0;
          } else {
            return {
                    TAG: /* Tools */5,
                    _0: /* LoadedDice */0
                  };
          }
      case "pateron" :
          if (slug.tl) {
            return /* Home */0;
          } else {
            return /* Patreon */3;
          }
      case "perfect-synergies" :
          if (slug.tl) {
            return /* Home */0;
          } else {
            return {
                    TAG: /* Tools */5,
                    _0: /* PerfectSynergies */1
                  };
          }
      case "player" :
          var match$18 = slug.tl;
          if (!match$18) {
            return {
                    TAG: /* Player */2,
                    _0: undefined
                  };
          }
          var match$19 = match$18.tl;
          if (!match$19) {
            return /* Home */0;
          }
          var match$20 = match$19.tl;
          var playerName = match$19.hd;
          var region$1 = match$18.hd;
          if (match$20) {
            var match$21 = match$20.tl;
            var tag = match$20.hd;
            if (match$21) {
              if (match$21.tl) {
                return /* Home */0;
              }
              var region$2 = Region.fromName(region$1);
              if (region$2 !== undefined) {
                return {
                        TAG: /* Player */2,
                        _0: {
                          region: region$2,
                          playerName: decodeURIComponent(playerName),
                          tag: tag,
                          match: match$21.hd
                        }
                      };
              } else {
                return {
                        TAG: /* Player */2,
                        _0: {
                          region: Region.getInitRegion(undefined),
                          playerName: "",
                          tag: undefined,
                          match: undefined
                        }
                      };
              }
            }
            var region$3 = Region.fromName(region$1);
            if (region$3 !== undefined) {
              if (tag.includes("_")) {
                return {
                        TAG: /* Player */2,
                        _0: {
                          region: region$3,
                          playerName: decodeURIComponent(playerName),
                          tag: undefined,
                          match: tag
                        }
                      };
              } else {
                return {
                        TAG: /* Player */2,
                        _0: {
                          region: region$3,
                          playerName: decodeURIComponent(playerName),
                          tag: tag,
                          match: undefined
                        }
                      };
              }
            } else {
              return {
                      TAG: /* Player */2,
                      _0: {
                        region: Region.getInitRegion(undefined),
                        playerName: "",
                        tag: undefined,
                        match: undefined
                      }
                    };
            }
          }
          var region$4 = Region.fromName(region$1);
          if (region$4 !== undefined) {
            return {
                    TAG: /* Player */2,
                    _0: {
                      region: region$4,
                      playerName: decodeURIComponent(playerName),
                      tag: undefined,
                      match: undefined
                    }
                  };
          } else {
            return {
                    TAG: /* Player */2,
                    _0: {
                      region: Region.getInitRegion(undefined),
                      playerName: "",
                      tag: undefined,
                      match: undefined
                    }
                  };
          }
      case "privacy" :
          if (slug.tl) {
            return /* Home */0;
          } else {
            return /* PrivacyPolicy */1;
          }
      case "team-builder" :
          var match$22 = slug.tl;
          if (match$22) {
            if (match$22.tl) {
              return /* Home */0;
            } else {
              return {
                      TAG: /* TeamPlanner */3,
                      _0: match$22.hd
                    };
            }
          } else {
            return {
                    TAG: /* TeamPlanner */3,
                    _0: Belt_Option.map(Caml_option.undefined_to_opt(qs.find(function (param) {
                                  return param[0] === "board";
                                })), (function (prim) {
                            return prim[1];
                          }))
                  };
          }
      case "team-compositions" :
          return {
                  TAG: /* Stats */0,
                  _0: {
                    TAG: /* TeamCompositions2 */10,
                    aperture: Aperture.fromUrl(Belt_List.toArray(slug.tl))
                  }
                };
      case "temp-comps" :
          return {
                  TAG: /* TempComps */1,
                  aperture: Aperture.fromUrl(Belt_List.toArray(slug.tl))
                };
      case "terms" :
          if (slug.tl) {
            return /* Home */0;
          } else {
            return /* TermsAndConditions */2;
          }
      case "tier-list" :
          return /* TierList */4;
      case "traits" :
          var match$23 = slug.tl;
          if (!match$23) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Traits */1,
                      aperture: Aperture.$$default
                    }
                  };
          }
          var match$24 = match$23.tl;
          if (!match$24) {
            return /* Home */0;
          }
          var slug$8 = match$24.tl;
          var traitRank = match$24.hd;
          var traitName = match$23.hd;
          var trait = Sd.fromUrlName(traitName, Config.statsSet);
          if (trait !== undefined) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Trait */5,
                      trait: trait,
                      rank: Belt_Option.getExn(Belt_Int.fromString(traitRank)),
                      aperture: Aperture.fromUrl(Belt_List.toArray(slug$8))
                    }
                  };
          }
          var slug_1$1 = {
            hd: traitRank,
            tl: slug$8
          };
          var slug$9 = {
            hd: traitName,
            tl: slug_1$1
          };
          return {
                  TAG: /* Stats */0,
                  _0: {
                    TAG: /* Traits */1,
                    aperture: Aperture.fromUrl(Belt_List.toArray(slug$9))
                  }
                };
      case "trends" :
          return {
                  TAG: /* Stats */0,
                  _0: {
                    TAG: /* Trends */13,
                    aperture: Aperture.fromUrl(Belt_List.toArray(slug.tl))
                  }
                };
      case "unit-builder" :
          var match$25 = slug.tl;
          if (match$25) {
            if (match$25.tl) {
              return /* Home */0;
            } else {
              return {
                      TAG: /* Tools */5,
                      _0: /* UnitBuilder */{
                        _0: match$25.hd
                      }
                    };
            }
          } else {
            return {
                    TAG: /* Tools */5,
                    _0: /* UnitBuilder */{
                      _0: undefined
                    }
                  };
          }
      case "units" :
          var match$26 = slug.tl;
          if (!match$26) {
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Units */0,
                      aperture: Aperture.$$default
                    }
                  };
          }
          var slug$10 = match$26.tl;
          var unitName = match$26.hd;
          var unitId = Sd.fromUrlName(unitName, Config.statsSet);
          if (unitId !== undefined) {
            var starLevel = Belt_Option.flatMap(Belt_List.head(slug$10), Belt_Int.fromString);
            if (starLevel !== undefined && starLevel >= 1 && starLevel <= 4) {
              return {
                      TAG: /* Stats */0,
                      _0: {
                        TAG: /* Unit */4,
                        unit: unitId,
                        starLevel: starLevel,
                        aperture: Aperture.fromUrl(Belt_List.toArray(slug$10))
                      }
                    };
            }
            return {
                    TAG: /* Stats */0,
                    _0: {
                      TAG: /* Unit */4,
                      unit: unitId,
                      starLevel: undefined,
                      aperture: Aperture.fromUrl(Belt_Array.slice(Belt_List.toArray(slug$10), 1, 99))
                    }
                  };
          }
          var slug$11 = {
            hd: unitName,
            tl: slug$10
          };
          return {
                  TAG: /* Stats */0,
                  _0: {
                    TAG: /* Units */0,
                    aperture: Aperture.fromUrl(Belt_List.toArray(slug$11))
                  }
                };
          break;
      case "wrapped" :
          var match$27 = slug.tl;
          if (!match$27) {
            return /* Home */0;
          }
          switch (match$27.hd) {
            case "set-10" :
                var match$28 = match$27.tl;
                if (!match$28) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 10,
                          _1: undefined
                        };
                }
                var match$29 = match$28.tl;
                if (!match$29) {
                  return /* Home */0;
                }
                var match$30 = match$29.tl;
                if (!match$30) {
                  return /* Home */0;
                }
                var match$31 = match$30.tl;
                var queueId = match$30.hd;
                var playerName$1 = match$29.hd;
                var region$5 = match$28.hd;
                if (match$31) {
                  if (match$31.tl) {
                    return /* Home */0;
                  }
                  var match$32 = Belt_Int.fromString(match$31.hd);
                  var match$33 = Region.fromName(region$5);
                  if (match$32 !== undefined && match$33 !== undefined) {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 10,
                            _1: {
                              region: match$33,
                              playerName: playerName$1,
                              queueId: match$32,
                              tag: queueId
                            }
                          };
                  } else {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 10,
                            _1: undefined
                          };
                  }
                }
                var match$34 = Belt_Int.fromString(queueId);
                var match$35 = Region.fromName(region$5);
                if (match$34 !== undefined && match$35 !== undefined) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 10,
                          _1: {
                            region: match$35,
                            playerName: playerName$1,
                            queueId: match$34,
                            tag: undefined
                          }
                        };
                } else {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 10,
                          _1: undefined
                        };
                }
            case "set-11" :
                var match$36 = match$27.tl;
                if (!match$36) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 11,
                          _1: undefined
                        };
                }
                var match$37 = match$36.tl;
                if (!match$37) {
                  return /* Home */0;
                }
                var match$38 = match$37.tl;
                if (!match$38) {
                  return /* Home */0;
                }
                var match$39 = match$38.tl;
                var queueId$1 = match$38.hd;
                var playerName$2 = match$37.hd;
                var region$6 = match$36.hd;
                if (match$39) {
                  if (match$39.tl) {
                    return /* Home */0;
                  }
                  var match$40 = Belt_Int.fromString(match$39.hd);
                  var match$41 = Region.fromName(region$6);
                  if (match$40 !== undefined && match$41 !== undefined) {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 11,
                            _1: {
                              region: match$41,
                              playerName: playerName$2,
                              queueId: match$40,
                              tag: queueId$1
                            }
                          };
                  } else {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 11,
                            _1: undefined
                          };
                  }
                }
                var match$42 = Belt_Int.fromString(queueId$1);
                var match$43 = Region.fromName(region$6);
                if (match$42 !== undefined && match$43 !== undefined) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 11,
                          _1: {
                            region: match$43,
                            playerName: playerName$2,
                            queueId: match$42,
                            tag: undefined
                          }
                        };
                } else {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 11,
                          _1: undefined
                        };
                }
            case "set-12" :
                var match$44 = match$27.tl;
                if (!match$44) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 120,
                          _1: undefined
                        };
                }
                var match$45 = match$44.tl;
                if (!match$45) {
                  return /* Home */0;
                }
                var match$46 = match$45.tl;
                if (!match$46) {
                  return /* Home */0;
                }
                var match$47 = match$46.tl;
                var queueId$2 = match$46.hd;
                var playerName$3 = match$45.hd;
                var region$7 = match$44.hd;
                if (match$47) {
                  if (match$47.tl) {
                    return /* Home */0;
                  }
                  var match$48 = Belt_Int.fromString(match$47.hd);
                  var match$49 = Region.fromName(region$7);
                  if (match$48 !== undefined && match$49 !== undefined) {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 120,
                            _1: {
                              region: match$49,
                              playerName: playerName$3,
                              queueId: match$48,
                              tag: queueId$2
                            }
                          };
                  } else {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 120,
                            _1: undefined
                          };
                  }
                }
                var match$50 = Belt_Int.fromString(queueId$2);
                var match$51 = Region.fromName(region$7);
                if (match$50 !== undefined && match$51 !== undefined) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 120,
                          _1: {
                            region: match$51,
                            playerName: playerName$3,
                            queueId: match$50,
                            tag: undefined
                          }
                        };
                } else {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 120,
                          _1: undefined
                        };
                }
            case "set-36" :
                var match$52 = match$27.tl;
                if (!match$52) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 36,
                          _1: undefined
                        };
                }
                var match$53 = match$52.tl;
                if (!match$53) {
                  return /* Home */0;
                }
                var match$54 = match$53.tl;
                if (!match$54) {
                  return /* Home */0;
                }
                var match$55 = match$54.tl;
                var queueId$3 = match$54.hd;
                var playerName$4 = match$53.hd;
                var region$8 = match$52.hd;
                if (match$55) {
                  if (match$55.tl) {
                    return /* Home */0;
                  }
                  var match$56 = Belt_Int.fromString(match$55.hd);
                  var match$57 = Region.fromName(region$8);
                  if (match$56 !== undefined && match$57 !== undefined) {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 36,
                            _1: {
                              region: match$57,
                              playerName: playerName$4,
                              queueId: match$56,
                              tag: queueId$3
                            }
                          };
                  } else {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 36,
                            _1: undefined
                          };
                  }
                }
                var match$58 = Belt_Int.fromString(queueId$3);
                var match$59 = Region.fromName(region$8);
                if (match$58 !== undefined && match$59 !== undefined) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 36,
                          _1: {
                            region: match$59,
                            playerName: playerName$4,
                            queueId: match$58,
                            tag: undefined
                          }
                        };
                } else {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 36,
                          _1: undefined
                        };
                }
            case "set-56" :
                var match$60 = match$27.tl;
                if (!match$60) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 56,
                          _1: undefined
                        };
                }
                var match$61 = match$60.tl;
                if (!match$61) {
                  return /* Home */0;
                }
                var match$62 = match$61.tl;
                if (!match$62) {
                  return /* Home */0;
                }
                var match$63 = match$62.tl;
                var queueId$4 = match$62.hd;
                var playerName$5 = match$61.hd;
                var region$9 = match$60.hd;
                if (match$63) {
                  if (match$63.tl) {
                    return /* Home */0;
                  }
                  var match$64 = Belt_Int.fromString(match$63.hd);
                  var match$65 = Region.fromName(region$9);
                  if (match$64 !== undefined && match$65 !== undefined) {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 56,
                            _1: {
                              region: match$65,
                              playerName: playerName$5,
                              queueId: match$64,
                              tag: queueId$4
                            }
                          };
                  } else {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 56,
                            _1: undefined
                          };
                  }
                }
                var match$66 = Belt_Int.fromString(queueId$4);
                var match$67 = Region.fromName(region$9);
                if (match$66 !== undefined && match$67 !== undefined) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 56,
                          _1: {
                            region: match$67,
                            playerName: playerName$5,
                            queueId: match$66,
                            tag: undefined
                          }
                        };
                } else {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 56,
                          _1: undefined
                        };
                }
            case "set-9" :
                var match$68 = match$27.tl;
                if (!match$68) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 90,
                          _1: undefined
                        };
                }
                var match$69 = match$68.tl;
                if (!match$69) {
                  return /* Home */0;
                }
                var match$70 = match$69.tl;
                if (!match$70) {
                  return /* Home */0;
                }
                var match$71 = match$70.tl;
                var queueId$5 = match$70.hd;
                var playerName$6 = match$69.hd;
                var region$10 = match$68.hd;
                if (match$71) {
                  if (match$71.tl) {
                    return /* Home */0;
                  }
                  var match$72 = Belt_Int.fromString(match$71.hd);
                  var match$73 = Region.fromName(region$10);
                  if (match$72 !== undefined && match$73 !== undefined) {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 90,
                            _1: {
                              region: match$73,
                              playerName: playerName$6,
                              queueId: match$72,
                              tag: queueId$5
                            }
                          };
                  } else {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 90,
                            _1: undefined
                          };
                  }
                }
                var match$74 = Belt_Int.fromString(queueId$5);
                var match$75 = Region.fromName(region$10);
                if (match$74 !== undefined && match$75 !== undefined) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 90,
                          _1: {
                            region: match$75,
                            playerName: playerName$6,
                            queueId: match$74,
                            tag: undefined
                          }
                        };
                } else {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 90,
                          _1: undefined
                        };
                }
            case "set-95" :
                var match$76 = match$27.tl;
                if (!match$76) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 95,
                          _1: undefined
                        };
                }
                var match$77 = match$76.tl;
                if (!match$77) {
                  return /* Home */0;
                }
                var match$78 = match$77.tl;
                if (!match$78) {
                  return /* Home */0;
                }
                var match$79 = match$78.tl;
                var queueId$6 = match$78.hd;
                var playerName$7 = match$77.hd;
                var region$11 = match$76.hd;
                if (match$79) {
                  if (match$79.tl) {
                    return /* Home */0;
                  }
                  var match$80 = Belt_Int.fromString(match$79.hd);
                  var match$81 = Region.fromName(region$11);
                  if (match$80 !== undefined && match$81 !== undefined) {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 95,
                            _1: {
                              region: match$81,
                              playerName: playerName$7,
                              queueId: match$80,
                              tag: queueId$6
                            }
                          };
                  } else {
                    return {
                            TAG: /* SetWrapped */4,
                            _0: 95,
                            _1: undefined
                          };
                  }
                }
                var match$82 = Belt_Int.fromString(queueId$6);
                var match$83 = Region.fromName(region$11);
                if (match$82 !== undefined && match$83 !== undefined) {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 95,
                          _1: {
                            region: match$83,
                            playerName: playerName$7,
                            queueId: match$82,
                            tag: undefined
                          }
                        };
                } else {
                  return {
                          TAG: /* SetWrapped */4,
                          _0: 95,
                          _1: undefined
                        };
                }
            default:
              return /* Home */0;
          }
      default:
        return /* Home */0;
    }
  };
}

function infoSetPart(set) {
  if (set !== undefined && set !== 130) {
    if (set !== 36) {
      return "/set-" + String(set);
    } else {
      return "/set-35";
    }
  } else {
    return "";
  }
}

function toString(route) {
  if (typeof route === "number") {
    switch (route) {
      case /* Home */0 :
          return "/";
      case /* PrivacyPolicy */1 :
          return "/privacy";
      case /* TermsAndConditions */2 :
          return "/terms";
      case /* Patreon */3 :
          return "/patreon";
      case /* TierList */4 :
          return "/tier-list/units";
      
    }
  } else {
    switch (route.TAG | 0) {
      case /* Stats */0 :
          var match = route._0;
          if (typeof match === "number") {
            if (match === /* Explorer */0) {
              return "/explorer";
            } else {
              return "/explorer-advanced";
            }
          }
          switch (match.TAG | 0) {
            case /* Units */0 :
                return "/units/" + Aperture.toUrl(match.aperture);
            case /* Traits */1 :
                return "/traits/" + Aperture.toUrl(match.aperture);
            case /* Items */2 :
                return "/items/" + Aperture.toUrl(match.aperture);
            case /* Augments */3 :
                return "/augments/" + Aperture.toUrl(match.aperture);
            case /* Unit */4 :
                return "/units/" + Sd.toUrlName(match.unit) + Belt_Option.mapWithDefault(match.starLevel, "", (function (v) {
                              return "/" + String(v);
                            })) + "/" + Aperture.toUrl(match.aperture);
            case /* Trait */5 :
                return "/traits/" + Sd.toUrlName(match.trait) + "/" + String(match.rank) + "/" + Aperture.toUrl(match.aperture);
            case /* Item */6 :
                return "/items/" + match.id + "/" + Aperture.toUrl(match.aperture);
            case /* Augment */7 :
                return "/augments/" + match.id + "/" + Aperture.toUrl(match.aperture);
            case /* Exalted */8 :
                return "/exalted/" + Aperture.toUrl(match.aperture);
            case /* ExaltedDetails */9 :
                return "/exalted/" + String(match.gameType) + "/" + String(match.tier) + "/" + Aperture.toUrl(match.aperture);
            case /* TeamCompositions2 */10 :
                return "/team-compositions/" + Aperture.toUrl(match.aperture);
            case /* HrComps */11 :
                if (match._0) {
                  return "/hyperroll-compositions/top";
                } else {
                  return "/hyperroll-compositions";
                }
            case /* DuoComps */12 :
                if (match._0) {
                  return "/duo-compositions/top";
                } else {
                  return "/duo-compositions";
                }
            case /* Trends */13 :
                return "/trends/" + Aperture.toUrl(match.aperture);
            
          }
      case /* TempComps */1 :
          return "/temp-comps/" + Aperture.toUrl(route.aperture);
      case /* Player */2 :
          var match$1 = route._0;
          if (match$1 !== undefined) {
            return "/player/" + Region.toUrlName(match$1.region) + "/" + match$1.playerName + Belt_Option.mapWithDefault(match$1.tag, "", (function (tag) {
                          return "/" + tag;
                        })) + Belt_Option.mapWithDefault(match$1.match, "", (function (m) {
                          return "/" + m;
                        }));
          } else {
            return "/player";
          }
      case /* TeamPlanner */3 :
          var board = route._0;
          if (board !== undefined) {
            return "/team-builder/" + board;
          } else {
            return "/team-builder";
          }
      case /* SetWrapped */4 :
          var pl = route._1;
          var set = route._0;
          if (pl === undefined) {
            return "/wrapped/set-" + String(set);
          }
          var tag = pl.tag;
          return "/wrapped/set-" + (
                  set > 100 ? String(set / 10 | 0) : String(set)
                ) + "/" + Region.toUrlName(pl.region) + "/" + pl.playerName + (
                  tag !== undefined ? "/" + tag : ""
                ) + "/" + String(pl.queueId);
      case /* Tools */5 :
          var build = route._0;
          if (typeof build === "number") {
            if (build !== 0) {
              return "/perfect-synergies";
            } else {
              return "/loaded-dice";
            }
          }
          var build$1 = build._0;
          return "/unit-builder" + (
                  build$1 !== undefined ? "/" + build$1 : ""
                );
      case /* Info */6 :
          var set$1 = route._0;
          if (typeof set$1 === "number") {
            switch (set$1) {
              case /* Main */0 :
                  return "/info";
              case /* Hyperroll */1 :
                  return "/info/hyperroll";
              case /* GoldExp */2 :
                  return "/info/gold-exp";
              case /* Glossary */3 :
                  return "/info/glossary";
              case /* CheatSheet */4 :
                  return "/info/cheat-sheet";
              case /* Pbe */5 :
                  return "/info/pbe";
              case /* TablesUnderground */6 :
                  return "/info/set-8/tables/underground-heist";
              case /* TablesAdmin */7 :
                  return "/info/set-8/tables/admin";
              case /* SetUpdate */8 :
                  return "/info/set-update";
              
            }
          } else {
            switch (set$1.TAG | 0) {
              case /* Unit */0 :
                  return "/info" + infoSetPart(set$1._0) + "/units/" + Sd.toUrlName(set$1._1);
              case /* Units */1 :
                  return "/info" + infoSetPart(set$1._0) + "/units";
              case /* Trait */2 :
                  return "/info" + infoSetPart(set$1._0) + "/traits/" + Sd.toUrlName(set$1._1);
              case /* Traits */3 :
                  return "/info" + infoSetPart(set$1._0) + "/traits";
              case /* Items */4 :
                  return "/info" + infoSetPart(set$1._0) + "/items";
              case /* Item */5 :
                  return "/info" + infoSetPart(set$1._0) + "/items/" + set$1._1;
              case /* Augments */6 :
                  return "/info" + infoSetPart(set$1._0) + "/augments";
              case /* PatchNotes */7 :
                  var patch = set$1._0;
                  if (patch !== undefined) {
                    return "/info/patch-notes/" + patch;
                  } else {
                    return "/info/patch-notes/" + Patch.render(Belt_Array.getExn(Belt_Array.keep(Config.allPatches, (function (p) {
                                          return p % 10 === 0;
                                        })), 0));
                  }
              
            }
          }
      case /* Leaderboards */7 :
          var key = route._0;
          if (typeof key === "number") {
            return "/leaderboards/bookmarks";
          }
          switch (key.TAG | 0) {
            case /* Ranked */0 :
                var region = key._0;
                if (region !== undefined) {
                  return "/leaderboards/" + Region.toUrlName(region);
                } else {
                  return "/leaderboards";
                }
            case /* Hyperroll */1 :
                var region$1 = key._0;
                if (region$1 !== undefined) {
                  return "/leaderboards/hyperroll/" + Region.toUrlName(region$1);
                } else {
                  return "/leaderboards/hyperroll";
                }
            case /* Duo */2 :
                var region$2 = key._0;
                if (region$2 !== undefined) {
                  return "/leaderboards/duo/" + Region.toUrlName(region$2);
                } else {
                  return "/leaderboards/duo";
                }
            case /* Unit */3 :
                var unitId = key._0;
                if (unitId !== undefined) {
                  return "/leaderboards/unit/" + Sd.toUrlName(unitId);
                } else {
                  return "/leaderboards/unit";
                }
            case /* Custom */4 :
                return "/leaderboards/custom/" + key._0;
            
          }
      
    }
  }
}

function replace(route) {
  return RescriptReactRouter.replace(toString(route));
}

function push(route) {
  return RescriptReactRouter.push(toString(route));
}

function fromAsPath(asPath) {
  var routeSplit = asPath.split("?");
  return fromPath(Belt_List.fromArray(Belt_Array.sliceToEnd(Belt_Array.getExn(routeSplit, 0).split("/"), 1)), Belt_Option.getWithDefault(Belt_Array.get(routeSplit, 1), ""));
}

var liveInfoSet = 130;

export {
  allLocales ,
  liveInfoSet ,
  parseQs ,
  toQs ,
  fromPath ,
  infoSetPart ,
  toString ,
  replace ,
  push ,
  fromAsPath ,
  
}
/* Sd Not a pure module */
