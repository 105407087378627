export const fetchApi = async (code: string, uid: string) => {
  // return fetch(`http://localhost:4000/patreon/login`, {
  return fetch(`https://api.tft.tools/patreon/login`, {
    method: "POST",
    body: JSON.stringify({
      code,
      uid,
    }),
  }).then((resp) => resp.json());
};

export const PatreonSvg = ({
  size,
  active,
}: {
  active: boolean;
  size: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -4.5 256 256"
      fill={active ? "var(--primary-10)" : "var(--primary-12)"}
    >
      <g>
        <path d="M45.1355837,0 L45.1355837,246.35001 L0,246.35001 L0,0 L45.1355837,0 Z M163.657111,0 C214.65668,0 256,41.3433196 256,92.3428889 C256,143.342458 214.65668,184.685778 163.657111,184.685778 C112.657542,184.685778 71.3142222,143.342458 71.3142222,92.3428889 C71.3142222,41.3433196 112.657542,0 163.657111,0 Z"></path>
      </g>
    </svg>
  );
};
