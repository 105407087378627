import { forwardRef, memo, useState, useEffect } from "react";
import { use as useSet } from "common/src/data/TftSet.bs";
import { unitTier } from "common/src/tft-static/Sd";
import { usePath4 as useBasePath4 } from "common/src/atoms/CdnAtom.bs";
import { getSrcSet2 } from "./ImgCore";
import * as T from "../bindings/Translation2";
import { PlainIcon } from "./TraitImage";
import { atom_show_unit_names } from "../SettingsCfg";
import { useAtom } from "jotai";

const getBorder = (unitId: string) => {
  switch (unitTier(unitId)) {
    case 5:
    case 6:
      return "border-[#ffc430e0]";
    case 4:
      return "border-[#de0ebde0]";
    case 3:
      return "border-[#54c3ffe0]";
    case 2:
      return "border-[#14cc73e0]";
    default:
      return "border-[#bbbbbbe0]";
  }
};

const getBorderClass = (borderSize: number) => {
  switch (borderSize) {
    case 0:
      return "border-0";
    case 1:
      return "border";
    case 2:
      return "border-2";
    default:
      return "";
  }
};

type Props = {
  className?: string;
  unitId: string;
  skin?: boolean;
  rounded?: string;
  size: number;
  sizeSm?: number;
  sizeMd?: number;
  sizeLg?: number;
  borderSize?: number;
  showName?: boolean;
  nameOffset?: number;
};

const UnitImage = memo(
  forwardRef(
    (
      {
        className = "",
        unitId: baseUnitId,
        skin: baseSkin = true,
        rounded = "rounded",
        size,
        sizeSm,
        sizeMd,
        sizeLg,
        borderSize = 2,
        nameOffset,
        showName,
        ...rest
      }: Props,
      ref,
    ) => {
      const [show_unit_names, _set_show_unit_names] =
        useAtom(atom_show_unit_names);

      let unitId;
      if (baseUnitId === "tft9_reksai") {
        unitId = "TFT9_RekSai";
      } else if (baseUnitId.startsWith("TFT9_Ryze")) {
        unitId = "TFT9_Ryze";
      } else {
        unitId = baseUnitId;
      }

      const set = useSet();
      const basePath4 = useBasePath4();

      const skin = unitId.startsWith("TFT9") ? false : baseSkin;

      const src = skin
        ? `${basePath4}/face/${unitId}.jpg`
        : `${basePath4}/face_base/${unitId.split("_")[1]}.jpg`;

      const border = getBorder(unitId);

      const borderClass = getBorderClass(borderSize);
      const [sizes, srcSet, sizeClass] = getSrcSet2(
        src,
        size,
        sizeSm,
        sizeMd,
        sizeLg,
        2,
      );

      if (baseUnitId.startsWith("TFT9_Ryze") && baseUnitId !== "TFT9_Ryze") {
        let traitId = "Set9_" + baseUnitId.slice(9);
        if (traitId === "Set9_BandleCity") {
          traitId += "2";
        }
        return (
          <div className={`relative flex-shrink-0`}>
            <img
              loading="lazy"
              decoding="async"
              alt={T.units("TFT9_Ryze")}
              src={src + `?w=${size - 4}`}
              className={`aspect-square ${borderClass} ${className} ${rounded} ${border} ${sizeClass}`}
              // @ts-ignore
              ref={ref}
              sizes={sizes}
              srcSet={srcSet}
              {...rest}
            />
            <div className="absolute left-[2px] top-[2px]">
              <PlainIcon traitId={traitId} size={size > 48 ? 24 : 18} />
            </div>
          </div>
        );
      } else {
        let img = (
          <img
            loading="lazy"
            decoding="async"
            alt={unitId.startsWith("TFT_") ? T.common(unitId) : T.units(unitId)}
            src={src + `?w=${size - 4}`}
            className={`aspect-square flex-shrink-0 ${borderClass} ${className} ${rounded} ${border} ${sizeClass}`}
            // @ts-ignore
            ref={ref}
            sizes={sizes}
            srcSet={srcSet}
            {...rest}
          />
        );
        if (show_unit_names && showName !== false) {
          return (
            <div className="relative flex-shrink-0">
              {img}
              <div
                style={{
                  bottom: (nameOffset ?? 0) + "px",
                }}
                className="text-sm absolute right-0 w-[calc(100%-6px)] mx-[3px] whitespace-nowrap overflow-hidden text-clip text-center text-shadow-strong"
              >
                {unitId.startsWith("TFT_") ? T.common(unitId) : T.units(unitId)}
              </div>
            </div>
          );
        } else {
          return img;
        }
      }
    },
  ),
);

export default UnitImage;
