import { memo, forwardRef } from "react";
import {
  usePath4 as useBasePath4,
  usePath5 as useBasePath5,
} from "common/src/atoms/CdnAtom.bs";
import { use as useSet } from "common/src/data/TftSet.bs";
import * as Sd from "common/src/tft-static/Sd";
import { getSizeClass } from "./ImgCore";
import { genHexPath } from "common/src/T.bs";
import { useT2 } from "../bindings/Translation.bs";
import { useTheme } from "../atoms/ThemeAtom.bs";

type PlainIconProps = {
  className?: string;
  traitId: string;
  rank?: number;
  size: number;
  sizeSm?: number;
  sizeMd?: number;
  sizeLg?: number;
};

export const PlainIcon = memo(
  forwardRef(
    (
      {
        className = "",
        traitId,
        rank = 0,
        size,
        sizeSm,
        sizeMd,
        sizeLg,
        ...rest
      }: PlainIconProps,
      ref,
    ) => {
      const theme = useTheme();
      const traitsT = useT2("traits");
      const basePath = useBasePath5();
      const path = `${basePath}/static/trait-icons`;
      const set = useSet();
      const style = Sd.traitStyle(traitId, rank, set);
      const rankKey =
        style === 0 ? (theme === "light" ? "" : "_w") : "_" + style;

      const sizeClass = getSizeClass(size, sizeSm, sizeMd, sizeLg);
      const src = traitId.includes("Teamup")
        ? `${basePath}/img/traits2/${traitId}${rankKey}.png?w=${size}`
        : `${path}/${traitId}${rankKey}.svg`;

      return (
        <img
          alt={`${traitsT(traitId)} ${rank}`}
          className={`aspect-square ${className} ${sizeClass}`}
          src={src}
          // @ts-ignore
          ref={ref}
          opacity={style === 0 ? "0.87" : "1"}
          {...rest}
        />
      );
    },
  ),
);

const getFill = (style: number) => {
  switch (style) {
    case 0:
      return "#ebf6ffb0";
    case 1:
      return "#b4702d";
    case 2:
      return "#b7bdbe";
    case 3:
      return "#d0b30b";
    case 4:
      return "#b39ddb";
    case 99:
      return "#f76b15";
    default:
      return "magenta";
  }
};

type PlainIcon2Props = {
  className?: string;
  traitId: string;
  rank?: number;
  size: number;
  sizeSm?: number;
  sizeMd?: number;
  sizeLg?: number;
  suffix?: string;
};

export const PlainIcon2 = memo(
  forwardRef(
    (
      {
        className = "",
        traitId,
        rank = 0,
        size,
        sizeSm,
        sizeMd,
        sizeLg,
        suffix = "",
        ...rest
      }: PlainIcon2Props,
      ref,
    ) => {
      const basePath = useBasePath5();
      const path = `${basePath}/static/trait-icons`;
      const set = useSet();
      const style =
        set >= 10 && set < 30 && Sd.isTraitUnique(traitId, set)
          ? 99
          : Sd.traitStyle(traitId, rank, set) || 0;
      const unitCount =
        rank == 0 ? 0 : Sd.traitRankUnitCount(traitId, rank, set);
      const rankKey = style;

      const sizeClass = getSizeClass(size, sizeSm, sizeMd, sizeLg);
      const src = traitId.includes("Teamup")
        ? `${basePath}/img/traits2/${traitId}_${rankKey}.png?w=${size}`
        : `${path}/${traitId}_${rankKey}.svg`;

      return (
        <svg
          viewBox="0 0 30 30"
          fill={getFill(style)}
          // @ts-ignore
          ref={ref}
          {...rest}
          className={`aspect-square ${className} ${sizeClass}`}
        >
          <mask id={`myMask-${unitCount}`}>
            <rect x="0" y="0" width="50" height="50" fill="white" />
            <text
              x={suffix === "" ? "28" : "30"}
              y="28"
              textAnchor="end"
              fontSize="18"
              fontFamily="Montserrat"
              strokeWidth="3"
              stroke="black"
              fontWeight="600"
              fill="black"
            >
              {unitCount}
              {suffix}
            </text>
          </mask>
          <image
            mask={`url(#myMask-${unitCount})`}
            href={src}
            x="-2"
            y="-2"
            width="28"
            height="28"
            opacity={style === 0 ? "0.87" : "1"}
          />
          <text
            x={suffix === "" ? "28" : "30"}
            y="28"
            textAnchor="end"
            fontSize="18"
            fontFamily="Montserrat"
            fontWeight="600"
          >
            {unitCount}
            {suffix}
          </text>
        </svg>
      );
    },
  ),
);

let fullBorder = genHexPath(16);
let highlight = genHexPath(14.75);
let innerBorder = genHexPath(13.75);

type InnerProps = {
  traitId: string;
  rank?: number;
};

export const Inner = memo(({ traitId, rank = 0 }: InnerProps) => {
  const theme = useTheme();
  const basePath4 = useBasePath4();
  const basePath = useBasePath5();
  const path = `${basePath}/static/trait-icons`;
  const set = useSet();
  const style = Sd.traitStyle(traitId, rank, set);
  const isUnique = Sd.isTraitUnique(traitId, set);

  if (style === 0) {
    return (
      <>
        <path fill={theme === "light" ? "#eee" : "#111"} d={fullBorder} />
        <path
          transform="translate(2, 2.5)"
          stroke={theme === "light " ? "#bbb" : "#555"}
          strokeWidth="1.5"
          fill="transparent"
          d={innerBorder}
        />
        <image
          x="4.25"
          y="6.25"
          width="19.5"
          height="19.5"
          opacity="0.87"
          href={`${path}/${traitId}${theme === "light" ? "" : "_w"}.svg`}
        />
      </>
    );
  } else {
    return (
      <>
        <image
          transform="translate(0, 0)"
          width="28"
          href={`${basePath4}/general/trait_${isUnique ? 5 : style}.png?w=28`}
        />
        <image
          x="3"
          y="4.5"
          width="22"
          height="22"
          href={`${path}/${traitId}.svg`}
        />
      </>
    );
  }
});

type Props = {
  className?: string;
  traitId: string;
  rank?: number;
  size: number;
  sizeSm?: number;
  sizeMd?: number;
  sizeLg?: number;
  chosen?: boolean;
};

const TraitImage = memo(
  forwardRef(
    (
      {
        className,
        size,
        sizeSm,
        sizeMd,
        sizeLg,
        traitId,
        rank,
        chosen,
        ...rest
      }: Props,
      ref,
    ) => {
      const sizeClass = getSizeClass(
        size + 4,
        sizeSm && sizeSm + 4,
        sizeMd && sizeMd + 4,
        sizeLg && sizeLg + 4,
      );
      const baseSize = sizeLg || sizeMd || sizeSm || size;

      return (
        <svg
          width={baseSize + 4}
          height={baseSize + 4}
          className={`${
            chosen ? "chosen-highlight" : ""
          } m-[-2px] aspect-square ${className} ${sizeClass}`}
          // @ts-ignore
          ref={ref}
          viewBox="-4 -2 36 36"
          {...rest}
        >
          <Inner traitId={traitId} rank={rank} />
          {chosen ? (
            <path
              transform="translate(1.5, 1.5)"
              d={highlight}
              fill="none"
              strokeWidth="2.5"
              stroke="url('#headliner-gradient')"
            />
          ) : null}
        </svg>
      );
    },
  ),
);

export default TraitImage;
