// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Global from "./Global.bs.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import JsCookie from "js-cookie";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LocalStorage from "./LocalStorage.bs.js";

var key = "ab2";

var cookieKey = "tt-key";

function forceAb(num) {
  LocalStorage.set(key, String(num));
  JsCookie.set(cookieKey);
  JsCookie.set(cookieKey, num);
  
}

if (typeof window !== "undefined") window.forceAb = forceAb
;

function getAbKey(param) {
  if (!Global.isBrowser(undefined)) {
    return 0;
  }
  var v = Belt_Option.flatMap(Caml_option.nullable_to_opt(LocalStorage.get(key)), Belt_Int.fromString);
  if (v !== undefined) {
    return v;
  }
  var v$1 = Js_math.floor(Math.random() * 1000);
  LocalStorage.set(key, String(v$1));
  return v$1;
}

function use(param) {
  return React.useMemo((function () {
                var key = getAbKey(undefined);
                var ck = JsCookie.get(cookieKey);
                if (ck !== undefined) {
                  
                } else {
                  JsCookie.set(cookieKey, key);
                }
                return key;
              }), []);
}

export {
  key ,
  cookieKey ,
  forceAb ,
  getAbKey ,
  use ,
  
}
/*  Not a pure module */
